<template>
    <div class="p-3">
        b2c invoices
    </div>
</template>

<script>
export default {
    name: "B2cInvoice",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
